.login-page{
    height: max(100vh, 50rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("./assets/images/bkg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.tabs-link{
    border-bottom: #dedede 1px solid;
}

.tabs-link a {
    white-space: nowrap;
    text-decoration: none;
    color:#7B7B7B;
    padding-block: 1rem;
    padding-inline: 1.5rem;
}

.active {
    border-bottom: 2px solid #7B7B7B;
}

@media screen and (max-width: 768px){
    .tabs-link{
        overflow-x: scroll
    }
    .tabs-link a {
        padding-block: 0.5rem;
        padding-inline: 1rem;
    }
}
